import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import SEO from '../components/seo'

const ResourcesPage = () => (
  <Layout>
    <Helmet>
      <body className="community resources" />
    </Helmet>

    <SEO title="Developers" keywords={['developer tools', 'developers', 'sdk']}
         description="The tools you need to build Proof of Process apps." />

    <main className="content">
      <h2>FAQ</h2>

      <h3>Lorem ipsum dolor sit amet, consectetur elit?</h3>
      <p>Dignissim suspendisse in est ante in nibh mauris cursus. Scelerisque eu ultrices vitae auctor eu augue ut lectus. Ullamcorper sit amet risus nullam eget felis.</p>

      <h3>Nulla tellus felis, vehicula lobortis dui et??</h3>
      <p>u ultrices vitae auctor eu augue ut. Sem et tortor consequat id porta. Odio eu feugiat pretium nibh ipsum consequat nisl.</p>

      <h3>Nunc risus nibh, ultrices eu pellentesque sit amet, varius?</h3>
      <p>Mauris ultrices eros in cursus turpis massa tincidunt. At risus viverra adipiscing at in tellus.</p>

      <h3>Lorem ipsum luctus libero?</h3>
      <p>Dignissim suspendisse in est ante in nibh mauris cursus. Scelerisque eu ultrices vitae auctor eu augue ut lectus. Ullamcorper sit amet risus nullam eget felis.</p>

      <h3>Nunc condimentum turpis sed enim dictum?</h3>
      <p>Tincidunt vitae semper quis lectus nulla at <a href="#">volutpat diam</a>.</p>

      <h3>Nulla tellus felis, vehicula lobortis dui et condimentum turpis sed enim dictum?</h3>
      <p>Mauris ultrices eros in cursus turpis massa tincidunt. At risus viverra adipiscing at in tellus.</p>
    </main>

    <nav id="sidebar"></nav>
    <aside id="navigation"></aside>
  </Layout>
)

export default ResourcesPage
